@import "src/styles/palette";

.container {
  background: white;
  padding: 15px;
  margin-bottom: 10px;
}

.inputContainer {
  margin-bottom: 22px;
}

.header {
  display: flex;
  align-items: center;
}

.expand {
  cursor: pointer;
  margin-left: auto;
}

.close {
  cursor: pointer;
}

.restrictionContainerTitle {
  margin-bottom: 10px;
}
