@import "styles/palette";
@import "styles/mixins";

.container {
  display: flex;
  color: $dark-grey;
  font-size: 16px;
  align-items: center;
  text-decoration: none;
  padding: 10px 15px;

  &:hover {
    background: $blue-grey;
  }
}

.active {
  background: $blue-grey;
  color: $primary-blue;

  .icon {
    background-color: $primary-blue;
  }

  .reactIcon {
    color: $primary-blue;
  }
}

.reactIcon {
  width: 30px;
  height: 30px;
  color: $mid-grey;
}

.icon {
  width: 30px;
  height: 30px;
  background-color: $mid-grey;
}

.label {
  margin-left: 10px;
  margin-right: 20px;

  @include up-to-desktop {
    display: none;
  }
}
