.container {
  display: flex;
  flex: 1;
  align-items: center;
}

.value {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.copy {
  cursor: pointer;
  margin-left: 15px;
}
