.container {
  flex: 1;
  align-items: center;
  justify-content: center;

  h4 {
    margin-bottom: 25px;
  }
}

.card {
  min-width: 450px;
  background: white;
  border-radius: 10px;
}

.input {
  margin-bottom: 22px;
}

.inputAttribute {
  margin-bottom: 22px;
  padding-right: 13em;
}

.button {
  width: 100%;
}

.attributeTitle {
  font-size: 14px;
  margin-bottom: 12px;
}

.header {
  display: flex;
  align-items: center;
}

.close {
  cursor: pointer;
  margin-left: auto;
  margin-bottom: 15px;
}
