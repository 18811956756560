@import "styles/palette";

.modal {
  padding: 20px;
}

.container {
  min-width: 280px;

  h4 {
    margin-bottom: 20px;
  }
}

.qr {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
}

.copy {
  display: flex;
  align-items: center;

  label {
    color: $dark-grey;
    font-size: 12px;
    margin-right: 15px;
  }

  .copyValue {
    width: 200px;
    color: $mid-grey;
    font-size: 14px;
  }
}
