@import "styles/palette";

.container {
  font-weight: normal;
  text-align: left;
  padding: 19px 10px;
  flex: 1;
  width: 0px;
  white-space: nowrap;
  color: $mid-grey;
  display: flex;
  align-items: center;
  overflow: hidden;

  > div {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
