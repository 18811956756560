$label-width: 200px;

.container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;

  h4 {
    margin-bottom: 25px;
  }
}

.body {
  padding: 0 $label-width 0 0;
}

.form {
  width: 500px;
  display: flex;
  flex-direction: column;
}

.sectionHeader {
  margin-left: calc(#{$label-width} + 20px);
  margin-bottom: 18px;
  font-size: 18px;
  font-weight: bold;
}

.input {
  margin-bottom: 22px;
}

.button {
  margin-top: 30px;
  margin-left: calc(#{$label-width} + 20px);
}
