.modal {
  padding: 20px;
}

.container {
  h4 {
    margin-bottom: 20px;
  }
}

.copyAccessToken {
  margin: 30px 0;
}

.submitModal {
  margin-left: auto;
}
