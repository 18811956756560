.modal {
  padding: 20px;
}

.body {
  padding: 20px 0;
}

.footer {
  display: flex;
}

.cancel {
  margin-left: auto;
  margin-right: 5px;
}
