@import "src/styles/palette";

.header {
  display: flex;
  cursor: pointer;
}

.value {
  color: $primary-blue;
}
