@import "styles/palette";

.container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  width: 350px;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    widows: 100%;
    margin-bottom: 50px;
  }

  a {
    width: 100%;
    text-decoration: none;
    margin-bottom: 20px;
    display: block;

    button {
      width: 100%;
    }
  }
}
