@import "src/styles/palette";

.table {
  width: 100%;
}

.name {
  color: $primary-blue;
  cursor: pointer;
}

.checkbox {
  flex: 0 0 25px;
}

.date {
  flex: 0 0 180px;
}

.status {
  flex: 0 0 180px;
}
