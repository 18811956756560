.container {
  flex: 1;
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: auto;
}

.body {
  display: flex;
  flex-direction: column;
  padding-top: 10%;
  width: 80%;
}
