@import "styles/palette";
@import "styles/mixins";

.card {
  padding: 35px 0 35px 25px;
  background: $blue-grey;
  border: 1px solid $light-grey-2;
}

.header {
  display: flex;
  align-items: center;
  padding-right: 35px;
}

.title {
  color: $dark-grey;
  font-size: 24px;
  margin-bottom: 20px;
  flex: 1;
}

.list {
  padding-right: 25px;
  display: flex;
  flex-wrap: wrap;

  @include up-to-tablet {
    > * {
      width: 100%;
      margin-bottom: 16px;
    }
  }

  @include tablet-and-up {
    > * {
      width: calc(50% - 8px);
      margin-bottom: 16px;

      &:nth-child(odd) {
        margin-right: 8px;
      }

      &:nth-child(even) {
        margin-left: 8px;
      }
    }
  }
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  > div {
    font-size: 18px;
    margin-bottom: 30px;
  }
}
