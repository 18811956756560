@import "./palette";

.detailsSectionHeaderContainer {
  height: 40px;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.detailsSectionHeader {
  font-size: 16px;
  color: $mid-grey;
}
