@import "styles/palette";

.table {
  width: 100%;
}

.url {
  color: $primary-blue;
}

.iconButton {
  cursor: pointer;
  margin-left: 5px;
}

.miniContainer {
  border: 1px solid rgb(182, 175, 175);
  border-radius: 10%;
  background-color: white;
  margin-right: 4px;
  display: inline-flex;
  padding: 5px;
  font-size: 12px;
}
