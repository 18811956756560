@import "styles/palette";

.horizontal {
  display: flex;

  > .label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    margin-right: 30px;
  }
}

.label {
  margin-bottom: 12px;
}
