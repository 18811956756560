.container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  width: 350px;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h6 {
    margin-bottom: 30px;
  }
}
