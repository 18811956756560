@import "styles/palette";

.input {
  display: flex;
  height: 43px;
  font-size: 1rem;
  padding: 0 15px;
  outline: none;
  box-shadow: none;
  background: $input-background;
  border: 1px solid $input-border-color;
  border-radius: 0.25rem;
  width: 100%;

  &[disabled] {
    cursor: not-allowed;
  }
}
