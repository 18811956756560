html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #29364b; /* $dark-grey */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #2f44c1; /* $primary-blue */
}

h4 {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 30px;
}

h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 20px;
}
