.container {
  flex: 1;
  align-items: center;
  justify-content: center;
  overflow: auto;

  h4 {
    margin-bottom: 25px;
  }
}

.card {
  width: 450px;
  background: white;
  border-radius: 10px;
  height: 100%;
  overflow: auto;
}

.input {
  margin-bottom: 22px;
}

.button {
  width: 100%;
}

.inputAttributes {
  margin-bottom: 22px;
  padding-right: 15em;
}

.inputAttributesTitle {
  font-size: 14px;
  margin-bottom: 12px;
}
.header {
  display: flex;
  align-items: center;
}

.close {
  cursor: pointer;
  margin-left: auto;
  margin-bottom: 17px;
}
