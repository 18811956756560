@import "styles/palette";

.container {
  display: none;
  min-width: 150px;
  position: absolute;
  z-index: 10;
  flex-direction: column;
  border: 1px solid $light-grey-2;
  background-color: white;

  &.show {
    display: flex;
  }
}

.item {
  cursor: pointer;
  padding: 10px;

  &:hover {
    background: $light-grey;
  }
}
