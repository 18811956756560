@import "styles/palette";

.container {
}

.label {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 3px;
}

.value {
  font-size: 14px;
}
