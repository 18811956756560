@import "styles/palette";

@mixin normal($color) {
  background: $color;
  color: white;

  &:hover {
    background: lighten($color, 10%);
  }

  &[disabled] {
    background: lighten($color, 30%);
  }
}

@mixin outlined($color) {
  background: white;
  color: $color;
  border: 2px solid $color;

  &:hover {
    background: lighten($color, 50%);
  }

  &[disabled] {
    background: lighten($color, 30%);
  }
}

@mixin tableText($color) {
  color: $color;

  &:hover {
    background: transparent;
    color: darken($color, 50%);
  }
}

.button {
  outline: none;
  color: white;
  background: $primary-blue;
  border-radius: 6px;
  border: 0;
  height: 42px;
  padding: 0 35px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;

  &.icon-button {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    padding: 0;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  /* default style - variant = none|primary */
  @include normal($primary-blue);

  &.warning {
    @include normal($warning-yellow);
  }

  /* outlined style - variant = outlined */
  &.outlined {
    @include outlined($primary-blue);

    &.warning {
      @include outlined($warning-yellow);
    }
  }

  &.tableText {
    height: initial;
    background: transparent;
    padding: 0;

    @include tableText($primary-blue);

    &.warning {
      @include tableText($warning-yellow);
    }
  }
}
