@import "styles/palette";

.container {
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.02) 0px 3px 1px -2px, rgba(0, 0, 0, 0.01) 0px 1px 5px 0px;
  min-height: 60px;
  padding: 0 10px;
  z-index: 1;
}

.logo {
  display: flex;
  align-items: center;
}

.spacer {
  flex: 1;
}

.navLink {
  color: $dark-grey;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0 20px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.dropdownLink {
  text-decoration: none;
  color: $dark-grey;
}
