@import "styles/palette";

.container {
  margin-bottom: 10px;
  border-bottom: 1px solid $light-grey-2;

  label {
    font-size: 16px;
    padding: 0;
    margin: 0;
  }

  div {
    font-size: 14px;
    color: $mid-grey;
    margin-top: 5px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
