@import "styles/palette";

.container {
  display: flex;
  font-weight: normal;
  text-align: left;
  padding: 5px;
}

.row {
  display: flex;
  flex: 1;
  background: $blue-grey;

  .clickable & {
    &:hover {
      cursor: pointer;
      background: darken($blue-grey, 10%);
    }
  }
}
