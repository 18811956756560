@import "styles/palette";

.container {
  display: flex;
}

.left {
  padding-right: 35px;
  position: relative;
}

.leftBody {
  width: 280px;
}

.right {
  flex: 1;
}

.collapse {
  position: absolute;
  right: 10px;
  top: 10px;
  color: $primary-blue;
  cursor: pointer;
}
