@import "styles/palette";

.link {
  text-decoration: none;
}

.container {
  background: white;
  border: 1px solid $light-grey-2;
  display: flex;
  padding: 30px 20px;
  align-items: center;
}

.image {
  max-width: 80px;
  max-height: 80px;
}

.imageContainer {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.fallback {
  max-width: 80px;
  max-height: 80px;
  background: $light-grey;
}

.info {
  color: $dark-grey;
  padding-left: 30px;
  flex: 1;
}

.name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.network {
  font-size: 14px;
}
