$light-grey: #f4f6fa;
$light-grey-2: #cfd1d8;
$light-grey-3: #b7bbc2;
$blue-grey: #f6f7fb;
$mid-grey: #808a9a;
$dark-grey: #29364b;

$primary-blue: #2f44c1;
$heavy-orange: #d57700;
$mid-blue: #b9c2f5;

$warning-yellow: $heavy-orange;
$error-red: red;

$input-background: $blue-grey;
$input-border-color: #0000001f;
