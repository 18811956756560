.modal {
  padding: 20px;
}

.container {
  min-width: 350px;

  h4 {
    margin-bottom: 20px;
  }
}

.createType {
  margin-bottom: 20px;
}

.submitModal {
  margin-top: 20px;
  margin-left: auto;
}
