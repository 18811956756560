.modal {
  padding: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    margin-bottom: 20px;
  }
}
