@import "styles/palette";

.container {
  padding: 25px 25px 0 25px;
  flex: 1;
}

.header {
  display: flex;

  h4 {
    margin-bottom: 35px;
    flex: 1;
  }
}

.table {
  height: calc(100% - 100px);
}

.refresh {
  margin-left: 22px;
  padding: 10px;
}

.info {
  color: $dark-grey;
  font-size: 14px;
  margin-bottom: 30px;
}

.subContainer {
  height: calc(100% - 70px);
}
