@import "styles/palette";

.overlay {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  position: relative;
  background: white;
  border: 1px solid $light-grey-2;
  outline: none;
  max-height: 80vh;
  overflow: auto;
}

.close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  color: $mid-grey;
}
