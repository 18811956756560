@import "styles/palette";

.container {
  border-collapse: collapse;
  border: 1px solid $light-grey-2;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.emptyState {
  padding: 20px 10px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
}
