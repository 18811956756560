@import "styles/palette";

.input {
  display: flex;
  height: 43px;
  font-size: 1rem;
  padding: 0 15px;
  outline: none;
  box-shadow: none;
  background: $input-background;
  border: 1px solid $input-border-color;
  border-radius: 0.25rem;
  width: 100%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */

  &[disabled] {
    cursor: not-allowed;
  }
}
