@import "styles/palette";

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 25px 25px 0 25px;
}

.header {
  display: flex;
  margin-bottom: 15px;
  align-items: center;

  h4 {
    flex: 1;
  }
}

.card {
  padding: 15px;
  border: 1px solid $light-grey-2;
  margin-bottom: 25px;
}

.cardBody {
  padding: 25px;
  background: $blue-grey;
}

.cardHeader {
  padding: 0 0 15px 20px;
  color: $heavy-orange;
  font-weight: bold;
  font-size: 20px;
}

.accessToken {
  display: flex;
  padding-left: 10px;
}

.accessTokenBody {
  @extend .cardBody;
  display: flex;
  flex: 1;
  align-items: center;
}

.copy {
  cursor: pointer;
  margin-left: 15px;
}

.image {
  max-width: 80px;
  max-height: 80px;
}

.imageContainer {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: 20px;
}

.fallback {
  @extend .image;
  background: $light-grey;
}

.organizationDetailsBody {
  @extend .cardBody;
  display: flex;
  flex-wrap: wrap;

  > * {
    width: 50%;
    margin-bottom: 15px;
  }

  .organizationDetailsBodySingleRow {
    > * {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}
