@import "styles/palette";

.breadcrumbs {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: 70px;
  max-width: 50%;
  overflow: hidden;
}

.link {
  color: $primary-blue;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.item {
  display: flex;
  align-items: center;

  &:last-of-type {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.lastItem {
  color: $dark-grey;
}

.separator {
  margin: 0 10px;
}
