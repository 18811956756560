@import "src/styles/palette";

.container {
  background: $blue-grey;
  padding: 15px;
  margin-bottom: 10px;
}

.inputContainer {
  margin-bottom: 22px;
}

.input {
  background: white;
}

.inputCredentialMapping {
  background: white;
  padding-right: 10em;
}

.containerCredentialDefinitionIds {
  background: $blue-grey;
  margin-bottom: 10px;
}

.inputCredentialDefinitionIds {
  margin-bottom: 22px;
  padding-right: 13em;
  background: white;
}

.header {
  display: flex;
  align-items: center;
}

.close {
  cursor: pointer;
  margin-left: auto;
  margin-bottom: 17px;
}

.attributeMappingSection {
  padding: 14px;
}

.closeAttribute {
  cursor: pointer;
  float: right;
}
