@import "styles/palette";

.table {
  width: 100%;
}

.name {
  cursor: pointer;
  color: $primary-blue;
  overflow: hidden;
  text-overflow: ellipsis;
}

.supportsRevocationHeader {
  flex: 0 0 180px;
}

.supportsRevocation {
  @extend .supportsRevocationHeader;
  color: $dark-grey;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
