@import "src/styles/palette";

.container {
  background: $blue-grey;
  padding: 15px;
  margin-bottom: 10px;
}

.inputContainer {
  margin-bottom: 22px;
}

.input {
  background: white;
  padding-right: 18em;
}

.header {
  display: flex;
  align-items: center;
}

.close {
  cursor: pointer;
  margin-left: auto;
  margin-bottom: 15px;
}

.closeAttribute {
  cursor: pointer;
  margin-left: 15px;
  margin-top: 10px;
}

.attributeNamesLabel {
  margin-bottom: 10px;
}
