.container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  h4 {
    margin-bottom: 25px;
  }
}

.card {
  width: 450px;
  background: white;
  border-radius: 10px;
}

.input {
  margin-bottom: 22px;
}

.button {
  width: 100%;
}
