@import "styles/palette";

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 25px 25px 0 25px;
}

.header {
  display: flex;
  margin-bottom: 25px;

  h4 {
    flex: 1;
  }
}

.actions {
  display: flex;
  margin-left: auto;
}

.actionButton {
  margin-left: 22px;
}

.refresh {
  @extend .actionButton;
  padding: 10px;
}
