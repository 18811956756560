@import "styles/palette";

.container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  width: 450px;
  background: white;
  border-radius: 10px;

  h6 {
    margin-bottom: 30px;
  }
}

.input {
  margin-bottom: 22px;
}

.footer {
  color: $mid-grey;
  font-size: 14px;
  display: flex;
  float: right;
  margin-top: 35px;

  > div {
    margin-right: 5px;
  }

  a {
    text-decoration: none;
  }
}

.button {
  width: 100%;
}

.error {
  margin-bottom: 22px;
  color: $error-red;
}
