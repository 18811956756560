.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.body {
  display: flex;
  flex: 1;
  height: calc(100% - 52px);
  overflow: hidden;
}
