@import "styles/palette";

.table {
  width: 100%;
}

.name {
  cursor: pointer;
  color: $primary-blue;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actions {
  display: flex;
}

.iconButton {
  cursor: pointer;
  margin-left: 5px;
}
