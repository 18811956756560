.modal {
  padding: 20px;
  max-height: 80%;
  overflow: auto;
}

.container {
  min-width: 600px;

  h4 {
    margin-bottom: 20px;
  }
}

.createType {
  margin-bottom: 20px;
}

.submitModal {
  margin-top: 20px;
  margin-left: auto;
}
